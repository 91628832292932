export var portuguese =
    {
        "profile_form_name_1":"Nome",
        "profile_form_name_2":"Sobrenome (Opcional)",
        "profile_form_email":"E-mail",
        "profile_form_password":"Senha",
        "profile_form_phone":"Telefone (Opcional)",
        "profile_form_country":"Selecione seu pais (Opcional)",
        "profile_form_civil_id":"CPF (Opcional)",
        "profile_form_work_id":"CREA (Opcional)",
        "profile_form_language":"Selecione o idioma",
        "profile_form_btn_save":"SALVAR ATUALIZAÇÕES",
        "navigation_title_name_1":"Ferrovia",
        "navigation_title_name_2":"Rodovia",
        "navigation_title_name_3":"Hidrologia",
        "navigation_title_name_4":"Linha de energia",
        "navigation_title_name_5":"Duto",
        "navigation_title_name_6":"Barragem",
        "navigation_title_name_7":"Mata ciliar",
        "navigation_title_name_8":"Loteamento",
        "newrailway_title":"Projeto de ferrovia",
        "newrailway_description":"Projeto de ferrovia descrição",
        "newRoadway_title":"Projeto de rodovia",
        "newRoadway_description":"Projeto de rodovia descrição",
        "newrailway_change_params":"Crie ou modifique um padrão",
        "newrailway_change_params_close":"Fechar",
        "newrailway_card_title":"Crie um novo cálculo",
        "newrailway_card_title_textfield_title":"Titulo",
        "newrailway_card_description_textfield_title":"Descrição (opcional)",
        "newrailway_card_owner_textfield_title":"Proprietário (opcional)",
        "newrailway_card_params_textfield_title":"Selecione as configurações de parâmetros e preços",
        "newrailway_card_btn_crete_title":"Criar calculo",
        "btn_cancel_title":"Cancelar",
        "projectCreate_userParams_railway_change_card_tittle":"Configure preços e parâmetros",
        "projectCreate_userParams_railway_change_card_btn_newItem_tittle":"NOVO PADRÃO",
        "projectCreate_userParams_railway_change_card_select_lable":"Crie a partir dos parâmetros padrão:",
        "projectCreate_userParams_railway_change_header":[
            { text: 'Nome', align: 'start', sortable: true, value: 'name',},
            { text: 'Moeda', align: 'start', value: 'currency',},
            { text: 'Ações', value: 'actions', sortable: false },
        ],
        "projectCreate_userParams_railway_change_cardNew_projTittle_tittle":"Nome da configuração personalizada",
        "projectCreate_userParams_railway_change_cardNew_curency_tittle":"Moeda",
        "projectCreate_userParams_railway_change_cardNew_currencyRef_tittle":"Câmbio (referência USD)",
        "projectCreate_userParams_railway_change_cardNew_btn_name2":"CRIAR NOVO PADRÃO",
        "projectCreate_userParams_railway_change_cardCopy_tittle":"Copiar item",
        "projectCreate_userParams_railway_change_cardCopy_field_lable":"Copiar nome",
        "projectCreate_userParams_railway_change_cardCopy_delete_field_lable":"Você tem certeza de que quer deletar essa configuração?",
        "termsOfService_tittle": "Termos de uso",
        "privacyPolicy_tittle": "Politica de privacidade",
        "update_banner_text": "Atualize para obter calculos melhores",
        "support_component_text": "WWCALC processa dados ferroviários, rodoviários e hidrológicos, contando com uma infraestrutura robusta e escalável. Em breve, também processará estudos de linhas de transmissão, barragens, loteamentos, casas, edifícios, fazendas solares e muito mais. Nossos algoritmos buscam os melhores resultados socioeconômicos, de mercado, CAPEX e OPEX, além de alternativas ambientais.",
        "support_component_text2": "Suporte comercial",
        "support_component_text3": "Suporte técnico",
        "support_component_text4": "Ferrovia passo a passo",
        "support_component_text5": "Conhecendo o WWCALC",
        "support_component_text5_video_2": "Contato, suporte, registro e compra de créditos",
        "support_component_text5_video_3": "Parâmetros de cálculo",
        "support_component_text5_video_4": "Coordenadas e Mapa",
        "support_component_text5_video_5": "Documentos na nuvem",
        "support_component_text5_video_6": "Comparativo de alternativas",
        "support_component_text5_video_7": "Relatório Planta e Perfil",
        "support_component_text5_video_8": "Vetores no QGIS",
        "support_component_text5_video_9": "Plugin do QGIS",
        "support_component": "https://www.youtube.com/watch?v=_1h3LHmx37w",
        "support_component_video_2": "https://www.youtube.com/watch?v=CbOalnX9Wgk",
        "support_component_video_3": "https://www.youtube.com/watch?v=k7_DoLrjgdM",
        "support_component_video_4": "https://www.youtube.com/watch?v=76KEi8rygxQ",
        "support_component_video_5": "https://www.youtube.com/watch?v=cpkP6m4eyeY",
        "support_component_video_6": "https://www.youtube.com/watch?v=Dw9ZKrrDS0c",
        "support_component_video_7": "https://www.youtube.com/watch?v=nLpfBeWw8f8",
        "support_component_video_8": "https://www.youtube.com/watch?v=gE-HFDlz0jU",
        "support_component_video_9": "https://www.youtube.com/watch?v=UYaO8RaDQUg",
        "register_component_text1": "E-mail",
        "register_component_text2": "Digite seu melhor e-mail",
        "register_component_text3": "Senha",
        "register_component_text4": "Digite uma senha rígida",
        "register_component_text5": "Confirme sua senha",
        "register_component_text6": "Digite uma senha rígida",
        "register_component_text7": "Primeiro nome",
        "register_component_text8": "Seu primeiro nome",
        "register_component_text9": "Sobrenome (opcional)",
        "register_component_text10": "Seu último Nome",
        "register_component_text11": "Telefone opcional)",
        "register_component_text12": "Seu número de telefone",
        "register_component_text13": "Identidade civil (opcional)",
        "register_component_text14": "Sua identidade civil",
        "register_component_text15": "ID do trabalho (opcional)",
        "register_component_text16": "Seu ID de trabalho",
        "register_component_text17": "Clique aqui para ler os termos e se cadastrar",
        "register_component_text18": "Ocultar termos",
        "register_component_text19": "Li, compreendi e concordo com os Termos de Uso",
        "register_component_text20": "Li, compreendi e concordo com a Política de Privacidade",
        "register_component_text21": "Registro",
        "register_component_text22": "Você receberá um email para validação.",
        "register_component_text23": "Lembre-se de verificar sua pasta de spam.",
        "register_component_text24": "Após a validação você poderá usar o aplicativo.",
        "register_component_text25": "Já estou cadastrado. Vá para o login.",
        "pricing_component_text1": "Um crédito WWCALC",
        "pricing_component_text2": "custa hoje",
        "pricing_component_text3": "O preço dos créditos acompanha a variação cambial de Reais para Dolar. Aproveite para comprar agora!",
        "pricing_component_text4": "Custos de cálculo",
        "pricing_component_text5": "Valor",
        "pricing_component_text6": "Unidade",
        "passwordReset_component_text1": "Mudar",
        "passwordReset_component_text2": "senha para",
        "passwordReset_component_text3": "Aplicativo WWCALC.",
        "passwordReset_component_text4": "Senha",
        "passwordReset_component_text5": "Digite uma senha rígida",
        "passwordReset_component_text6": "Confirme sua senha",
        "passwordReset_component_text7": "Digite uma senha rígida",
        "passwordReset_component_text8": "Confirmar alteração de senha",
        "passwordReset_component_text9": "Senha alterada com sucesso. Aguarde enquanto você está logado.",
        "home_component_text1": "Conectando-se à sua nuvem",
        "home_component_text2": "Digite sua senha da nuvem para continuar",
        "home_component_text3": "Digite sua senha da nuvem",
        "home_component_text4": "Conectar",
        "home_component_text5": "Carregando seus dados",
        "home_component_text6": "Você não tem cálculo. Experimente alguns no menu esquerdo.",
        "cardForm_component_text1": "Número",
        "cardForm_component_text2": "Digite o número do seu cartão de crédito",
        "cardForm_component_text3": "Nome completo",
        "cardForm_component_text4": "Nome no cartão de crédito",
        "cardForm_component_text5": "Data de validade",
        "cardForm_component_text6": "CVV",
        "cardForm_component_text7": "CVV no verso do seu cartão de crédito",
        "cardForm_component_text8": "Cancelar",
        "cardForm_component_text9": "Salvar",
        "billingMercadoPago_component_text1": "COMPRE CRÉDITOS",
        "billingMercadoPago_component_text2": "Atualizar cartão de crédito",
        "billingMercadoPago_component_text3": "Comprar",
        "billingMercadoPago_component_text4": "TRANSAÇÕES DE CRÉDITOS",
        "billingMercadoPago_component_text5": "COMPRAR",
        "billingMercadoPago_component_text6": "Exportar",
        "billingMercadoPago_component_text7": "Compre créditos?",
        "billingMercadoPago_component_text8": "Confirmar a compra de",
        "billingMercadoPago_component_text9": "para",
        "billingMercadoPago_component_text10": "Agora não",
        "billingMercadoPago_component_text11": "Confirme",
        "updateDefaultParams_component_text1": "Restrição",
        "updateDefaultParams_component_text2": "Grade e Curvas",
        "updateDefaultParams_component_text3": "Plataforma",
        "updateDefaultParams_component_text4": "Terraplenagem",
        "updateDefaultParams_component_text5": "Salvar",
        "updateDefaultParams_component_text6": "Verificando os dados da versão, aguarde um momento.",
        "roadway_component_text1": "Próximo",
        "roadway_component_text2": "Erro interno",
        "roadway_component_text3": "Infelizmente ocorreu um erro durante o processamento, por esse motivo seus créditos serão reembolsados ​​e este projeto arquivado",
        "roadway_component_text4": "Excluir",
        "roadway_component_text5": "Título",
        "roadway_component_text6": "Descrição (opcional)",
        "roadway_component_text7": "Proprietário (opcional)",
        "roadway_component_text8": "Caso opte por continuar, serão sugeridas até 10 alternativas saindo do ponto inicial e chegando ao ponto final.",
        "roadway_component_text9": "Você vai usar",
        "roadway_component_text10": "créditos para ir para a próxima etapa do cálculo.",
        "roadway_component_text11": "Os cálculos são realizados considerando a área limite, o orçamento e os parâmetros escolhidos. Caso queira refazer o cálculo posteriormente, alterando algum parâmetro, é necessário criar um novo cálculo.",
        "roadway_component_text12": "Buscando dados para a área escolhida",
        "roadway_component_text13": "Encontrando bons caminhos",
        "roadway_component_text14": "Corredores calculados com sucesso! Vá para o próximo passo.",
        "roadway_component_text15": "Algo deu errado com o cálculo.",
        "roadway_component_text16": "Seus créditos serão devolvidos e a equipe técnica investigará o problema para que não aconteça novamente.",
        "roadway_component_text17": "Encontrando geometrias possíveis",
        "roadway_component_text18": "Comparando alternativas encontradas",
        "roadway_component_text19": "Alternativas calculadas com sucesso! Vá para a próxima etapa para compará-las.",
        "roadway_component_text20": "Usar créditos?",
        "roadway_component_text21": "Confirmar uso de",
        "roadway_component_text22": "Agora não",
        "roadway_component_text23": "Confirme",
        "roadway_component_text24": "Corredor ",
        "roadway_component_text25": "Alternativa ",
        "map_component_text1": "Voltar",
        "map_component_text2": "Camada",
        "map_component_text3": "CAMADAS BASE",
        "map_component_text4": "Importar",
        "map_component_text5": "Adicionar pontos",
        "map_component_text6": "Geometria SRID",
        "map_component_text7": "Formato de Coordenadas",
        "map_component_text8": "Geometria em formato GeoJson",
        "map_component_text9": "Geometria em formato Wkt",
        "map_component_text10": "Cancelar",
        "map_component_text11": "Adicionar",
        "map_component_text12": "Adicionar polilinhas",
        "map_component_text13": "Geometria em formato EWKT",
        "map_component_text14": "Adicionar polígonos",
        "map_component_text15": "Procurar",
        "map_component_text16": "Coloque o nome",
        "map_component_text17": "Apagar",
        "map_component_text18": "Próximo",
        "map_component_text19": "OBJETO ESTÁ SENDO IGNORADO",
        "map_component_text20": "Ignorar vetor",
        "map_component_text21": "Pronto",
        "map_component_text21_2": "Sair",
        "map_component_text22": "Lista de curvas",
        "map_component_text23": "Salvar",
        "detail_component_text1": "Mapa",
        "detail_component_text2": "Comparativo",
        "detail_component_text3": "Detalhar alternativas",
        "detail_component_text4": "Camada",
        "detail_component_text5": "CAMADAS BASE",
        "detail_component_text6": "Por favor, aguarde enquanto os vetores estão sendo carregados...",
        "detail_component_text7": "Carregando relatório de alternativas",
        "detail_component_text8": "Renderizando cálculos",
        "detail_component_text9": "SELECIONAR ALTERNATIVAS",
        "detail_component_text10": "Usar créditos?",
        "detail_component_text11": "Digite sua senha da nuvem para continuar",
        "detail_component_text12": "Agora não",
        "detail_component_text13": "Confirme",
        "detail_component_text14": "/_/report_alternative/Comparativo.pdf",
        "createNewRoadway_component_text1": "Fechar",
        "createNewRoadway_component_text2": "Criar novo cálculo",
        "createNewRoadway_component_text3": "Título",
        "createNewRoadway_component_text4": "Descrição (opcional)",
        "createNewRoadway_component_text5": "Proprietário (opcional)",
        "createNewRoadway_component_text6": "Selecionar preços e parâmetros já criados",
        "createNewRoadway_component_text7": "Criar cálculo",
        "createNewRoadway_component_text8": "Cancelar",
        "costMapUpdate_component_text1": "Restrições",
        "costMapUpdate_component_text2": "Cancelar",
        "costMapUpdate_component_text3": "Adicionar restrição",
        "costMapUpdate_component_text4": "Camadas do mapa",
        "costMapUpdate_component_text5": "CAMADAS BASE",
        "costMapUpdate_component_text6": "Buffer e vetores",
        "costMapUpdate_component_text7": "Vetores",
        "costMapUpdate_component_text8": "Buffer",
        "costMapUpdate_component_text9": "Limite",
        "costMapUpdate_component_point": "Pontos de passagem",
        "costMapUpdate_component_text10": "Recalcular",
        "costMapUpdate_component_text11": "Próximo",
        "costMapUpdate_component_text12": "Por favor, aguarde enquanto os vetores estão sendo carregados...",
        "costMapUpdate_component_text13": "Usar créditos?",
        "costMapUpdate_component_text14": "Confirmar uso de",
        "costMapUpdate_component_text15": "Digite sua senha da nuvem para continuar",
        "costMapUpdate_component_text16": "Digite sua senha da nuvem",
        "costMapUpdate_component_text17": "Agora não",
        "costMapUpdate_component_text18": "Confirme",
        "adjustPricesDefault_component_text1": "Restrições",
        "adjustPricesDefault_component_text2": "Grade e Curvas",
        "adjustPricesDefault_component_text3": "Plataforma",
        "adjustPricesDefault_component_text4": "Terraplenagem",
        "adjustPricesDefault_component_text5": "Salvar",
        "adjustPricesDefault_component_text5_2": "Cancelar",
        "adjustPricesDefault_component_text6": ['Sim', 'Não'],
        "adjustPricesDefault_component_text7": "Tem canteiro central?",
        "adjustPricesDefault_component_text8": "Número de pistas por sentido",
        "adjustPricesDefault_component_text9": "Barreira central",
        "adjustPricesDefault_component_text10": ['Nenhum', 'New Jersey', 'Defensa Metálica'],
        "adjustPricesDefault_component_text11": "Barreira lateral",
        "adjustPricesDefault_component_text12": "Sim",
        "adjustPricesDefault_component_text13": "Não",
        "adjustPricesDefault_component_text14": "Nenhum",
        "adjustPricesDefault_component_text15": "New Jersey",
        "adjustPricesDefault_component_text16": "Defensa Metálica",
        "adjustPrices_component_text1": "Restrições",
        "adjustPrices_component_text2": "Próximo",
        "adjustPrices_component_text3": "Anterior",
        "adjustPrices_component_text4": "Editar",
        "adjustParameters_component_text1": "Voltar",
        "adjustParameters_component_text2": "Grade e Curvas",
        "adjustParameters_component_text3": "Plataforma",
        "adjustParameters_component_text4": "Terraplenagem",
        "adjustParameters_component_text5": ['Sim', 'Não'],
        "adjustParameters_component_text6": "Tem canteiro central?",
        "adjustParameters_component_text7": ['Nenhum'],
        "adjustParameters_component_text7_2": 'Número de pistas por sentido',
        "adjustParameters_component_text8": "Barreira central",
        "adjustParameters_component_text9": ['Nenhum', 'New Jersey', 'Defensa Metálica'],
        "adjustParameters_component_text10": "Barreira central",
        "adjustParameters_component_text11": ['Nenhum', 'New Jersey', 'Defensa Metálica'],
        "adjustParameters_component_text12": "Barreira lateral",
        "adjustParameters_component_text14": "Não",
        "adjustParameters_component_text15": "Sim",
        "adjustParameters_component_text16": "Nenhum",
        "adjustParameters_component_text17": "New Jersey",
        "adjustParameters_component_text18": "Defensa Metálica",
        "curves_component_text1": "Nota",
        "curves_component_text2": "Caminho principal subida máxima (%)",
        "curves_component_text3": "Caminho oposto subida máxima (%)",
        "curves_component_text4": "Comprimento da curva vertical (m)",
        "curves_component_text5": "Superelevação",
        "curves_component_text6": "Velocidade operacional",
        "curves_component_text7": "Selecionar velocidade",
        "curves_component_text8": "Escolha o raio mínimo (m)",
        "curves_component_text9": "Região",
        "curves_component_text10": "Classes rodoviárias",
        "curves_component_text11": "CURVAS HORIZONTAIS",
        "curves_component_text12": "Novo item",
        "curves_component_text13": "Raio (m)",
        "curves_component_text14": "Espiral (m)",
        "curves_component_text15": "Penalidade",
        "curves_component_text16": "Editar",
        "curves_component_text17": "Legenda:",
        "curves_component_text18": "St - Superelevação Teórica",
        "curves_component_text19": "Cv – Centro de gravidade do veículo",
        "curves_component_text20": "F - Força peso",
        "curves_component_text21": "Fc - Força Centrífuga",
        "curves_component_text22": "Largura do medidor:",
        "curves_component_text23": "Tipo de modelo Rails",
        "curves_component_text24": "Velocidade operacional",
        "curves_component_text25": "Coeficiente de segurança",
        "curves_component_text26": "Coeficiente de velocidade",
        "curves_component_text27": "Raio mínimo",
        "earthworkCut1_component_text1": "Corte",
        "earthworkCut1_component_text2": "Nenhum",
        "earthworkCut1_component_text3": "New Jersey",
        "earthworkCut1_component_text4": "Defensa Metálica",
        "earthworkCut1_component_text5": "Altura",
        "earthworkCut1_component_text6": "Inclinação de corte",
        "earthworkCut1_component_text7": "Altura largura",
        "earthworkCut1_component_text8": "Declive",
        "earthworkCut1_component_text9": "Largura",
        "earthworkCut1_component_text10": "Corte 2ª categoria:",
        "earthworkCut1_component_text11": "% de Corte 1ª categoria",
        "earthworkCut1_component_text12": "Corte 3ª categoria:",
        "earthWorkFill1_component_text1": "Aterro",
        "earthWorkFill1_component_text2": "Nenhum",
        "earthWorkFill1_component_text3": "New Jersey",
        "earthWorkFill1_component_text4": "Defensa Metálica",
        "earthWorkFill1_component_text5": "Altura",
        "earthWorkFill1_component_text6": "Inclinação do Aterro",
        "earthWorkFill1_component_text7": "Altura largura",
        "earthWorkFill1_component_text8": "Inclinação do Aterro",
        "earthWorkFill1_component_text9": "Largura do aterro",
        "earthWorkTransport_component_text1": "Transporte",
        "earthWorkTransport_component_text2": "transporte",
        "earthWorkTransport_component_text3": "Altura de limpeza",
        "earthWorkTransport_component_text4": "Fator de empolamento",
        "earthWorkTransport_component_text5": "Fator de homogeneização",
        "earthWorkTransport_component_text6": "Distância Média do Empréstimo",
        "earthWorkTransport_component_text7": "Distância Média de Bota Fora",
        "platform1Lane_component_text1": "Superestrutura da Plataforma",
        "platform1Lane_component_text2": "Nenhum",
        "platform1Lane_component_text3": "New Jersey",
        "platform1Lane_component_text4": "Defensa Metálica",
        "platform1Lane_component_text5": "Largura de acostamento",
        "platform1Lane_component_text6": "densidade do asfalto",
        "platform1Lane_component_text7": "Largura da pista",
        "platform1Lane_component_text8": "Primeira profundidade de asfalto",
        "platform1Lane_component_text9": "Segunda profundidade de asfalto",
        "platform1Lane_component_text10": "Altura da base",
        "platform1Lane_component_text11": "Altura da sub-base",
        "platform1Lane_component_text12": "Largura mediana",
        "platformAquisition1Lane_component_text1": "Aquisição",
        "platformAquisition1Lane_component_text2": "Nenhum",
        "platformAquisition1Lane_component_text3": "New Jersey",
        "platformAquisition1Lane_component_text4": "Defensa Metálica",
        "platformAquisition1Lane_component_text5": "Largura de aquisição",
        "platformAquisition1Lane_component_text6": "Largura média de terraplenagem",
        "platformAquisition1Lane_component_text7": "Margem livre",
        "platformBridge_component_text1": "Ponte",
        "platformBridge_component_text2": "comprimento da ponte",
        "platformBridge_component_text3": "Altura máxima de aterro",
        "platformBridge_component_text4": "Margem da ponte",
        "platformBridge_component_text5": "Altura máxima da ponte",
        "platformBridge1Lane_component_text1": "Nenhum",
        "platformBridge1Lane_component_text2": "New Jersey",
        "platformBridge1Lane_component_text3": "Defensa Metálica",
        "platformBridge1Lane_component_text4": "Largura da ponte de 1 faixa",
        "platformBridge1Lane_component_text5": "Largura da ponte de 2 faixas",
        "platformTunnel_component_text1": "Túnel",
        "platformTunnel_component_text2": "comprimento do túnel",
        "platformTunnel_component_text3": "Altura máxima de corte",
        "platformTunnel_component_text4": "Margem do túnel",
        "platformTunnel1Lane_component_text1": "Nenhum",
        "platformTunnel1Lane_component_text2": "New Jersey",
        "platformTunnel1Lane_component_text3": "Defensa Metálica",
        "platformTunnel1Lane_component_text4": "Largura da túnel de 1 faixa",
        "platformTunnel1Lane_component_text5": "Largura da túnel de 2 faixas",
        "platformSleepers_component_text1": "Dormente",
        "platformSleepers_component_text2": "Profundidade",
        "platformSleepers_component_text3": "Largura",
        "platformSleepers_component_text4": "Comprimento",
        "platformSleepers_component_text5": "Espaçamento",
        "platform1Lane_railway_component_text1": "Superestrutura da Plataforma",
        "platform1Lane_railway_component_text2": "plataforma_1lane",
        "platform1Lane_railway_component_text3": "Margem sobressalente",
        "platform1Lane_railway_component_text4": "Largura do lastro",
        "platform1Lane_railway_component_text5": "Declive",
        "platform1Lane_railway_component_text6": "Densidade de trilhos",
        "platform1Lane_railway_component_text7": "Densidade de lastro",
        "platform1Lane_railway_component_text8": "Altura do lastro",
        "platform1Lane_railway_component_text9": "Inclinação de Lastro",
        "platform1Lane_railway_component_text10": "Altura largura",
        "platform1Lane_railway_component_text11": "Altura do Subalastro",
        "platform1Lane_railway_component_text12": "Distância rodoviária pavimentada para transporte comercial",
        "platform1Lane_railway_component_text13": "Distância rodoviária pavimentada primária para transporte comercial",
        "platform1Lane_railway_component_text14": "Distância rodoviária natural de transporte comercial",
        "platform1Lane_railway_component_text15": "Canteiro central",
        "login_component_text1": "Coloque sua senha",
        "login_component_text2": "Entrar",
        "login_component_text3": "Registro",
        "login_component_text4": "Redefinir senha",
        "login_component_text5": "Você receberá um email para validação.",
        "login_component_text6": "Lembre-se de verificar sua pasta de spam.",
        "login_component_text7": "Ocorreu algum erro. Se o problema persistir, entre em contato com a equipe de suporte.",
        "login_component_text8": "Playlist Tutoriais",
        "allSteps_array_obj": 
            {
                "railway": [
                    "Criar cálculo",
                    "Ajustar preços",
                    "Ajustar parâmetros",
                    "Definir coordenadas obrigatórias",
                    "Limitar área de caracterização",
                    "Calcular corredores",
                    "Calcular alternativas",
                    "Alternativas detalhadas"
                ],
                "roadway": [
                    "Criar cálculo",
                    "Ajustar preços",
                    "Ajustar parâmetros",
                    "Definir coordenadas obrigatórias",
                    "Limitar área de caracterização",
                    "Calcular corredores",
                    "Calcular alternativas",
                    "Alternativas detalhadas"
                ]
            },
        "curvesTable_roadway_text1": [
            ['Região',	'O',	'I',	'II',	'III', 'IV', ''],
            ['',	'',	'',	'',	'',	'A', 'B'],
            ['Terreno plano',	'540',	'375',	'375',	'230',	'230',	'125'],
            ['Terreno irregular',	'345',	'210',	'170',	'125',	'125',	'50'],
            ['Região montanhosa',	'210',	'115',	'80',	'50',	'50',	'25']
            ],
        "curvesTable_roadway_text2": [
            {name: 'Terreno plano', zero: 540, I: 375, II: 375, III: 230, A: 230, B: 125},
            {name: 'Terreno irregular', zero: 345, I: 210, II: 170, III: 125, A: 125, B: 50},
            {name: 'Região montanhosa', zero: 210, I: 115, II: 80, III: 50, A: 50, B: 25}
            ],

        "curves_comp_list_arr_of_obj_1": [
            { text: "Raio (m)", value: "Radius" },
            { text: "Espiral (m)", value: "Ls" },
            { text: "Penalidade (BRL/m)", value: "Penalty", },
            { text: "Superelevação (%)", value: "Superelevation" },
            { text: "Ações", value: "actions", sortable: false },
        ],
        "curves_comp_list_arr_of_obj_2": [
            { text: 'Código', value: 'Code', sortable: false},
            { text: 'Descrição', value: 'Description', sortable: false},
            { text: 'Custo', value: 'Cost', sortable: false},
        ],
        "curves_comp_list_arr_of_obj_3": [
            { text: 'Código', value: 'Code', sortable: false, width: '6%'},
            { text: 'Descrição', value: 'Description', sortable: false},
            { text: 'Unidade', value: 'Unit', sortable: false, width: '12%'},
            { text: 'Valor', value: 'Unit Cost', sortable: false},
            { text: 'Risco (%)', value: 'Risk', sortable: false, width: '9%'},
        ],
        "railway_params_lable_1": "Número de pistas",
        "railway_params_lable_2": "Selecionar largura",
        "railway_params_lable_3": "Selecionar tipo de trilho",
        "railway_params_lable_4": "Selecionar velocidade",
        "rigths_1": "Todos os direitos reservados",
        "link_cloud_text_1":"Alternativa",
        "terms_pdf":"Termo_pt-br_v2024-03-01",
        "poli_pdf":"Politica_pt-br_v2024-03-01",
        "terms_redirect":"terms-of-use-pt",
        "poli_redirect":"privacy-policy-pt",
        "help_text_1": "",
        "help_text_2": "Parâmetros do cálculo",
        "help_text_3": "Além de título, descrição e  proprietário, é necessário definir o conjunto de parâmetros para o cálculo A WWCALC fornece alguns padrões como base para você criar os seus. Crie um padrão, defina a taxa de conversão de dólar para a moeda escolhida. Você pode alterar o nome, copiar, apagar ou editar o seu padrão. Os parâmetros estão divididos nos grupos CAPEX, Restrições, Greide e curvas, Plataforma e Terraplenagem. As alterações só serão salvas no padrão após clicar no botão Salvar, no lado direito do menu superior. Salve quantas vezes forem necessárias.",
        "help_text_4": "Além de título, descrição e  proprietário, é necessário definir o conjunto de parâmetros para o cálculo A WWCALC fornece alguns padrões como base para você criar os seus.",
        "help_text_5": "",
        "help_text_6": "",
        "help_text_7": "Capex",
        "help_text_8": "No grupo CAPEX, você define o preço unitário e o risco de cada serviço. A porcentagem de risco será acrescida ao respectivo custo em todos os cálculos.",
        "help_text_15": "Escolha a superestrutura conforme necessidade. Os itens 12.02, 12.03, 12.06, 12.07 são ajustáveis.",
        "help_text_9": "Restrições",
        "help_text_10": "No grupo Restrições, você define as penalidades e principalmente as proibições de passar em área indigena, reserva natural, parque nacional, entre outros. Quanto maior a penalidade definida, menor a chance de passar no local. Observe que a unidade é metro quadrado.",
        "help_text_11": "Defina a taxa de conversão de dólar para a moeda escolhida.",
        "help_text_12": "Grade e Curvas",
        "help_text_13": "No grupo Grade e curvas, defina as rampas máximas. A rampa no sentido principal começa no ponto inicial e vai em direção ao ponto final. A rampa máxima em sentido contrário é do ponto final ao inicial. Quanto maior o comprimento da curva vertical, menor a quantidade de ocorrências nas alternativas. Escolha entre bitola métrica, mista e larga. Escolha o modelo de trilho. Cada modelo tem sua densidade, largura inferior, superior e altura pré-definidas. Defina a velocidade operacional e o coeficiente de segurança. Defina o coeficiente de velocidade para o cálculo da superelevação. Defina o raio mínimo e todas as curvas que queira usar na geometria horizontal da ferrovia. Coloque comprimentos de espiral ou zero, caso seja uma curva circular sem transição. Coloque penalidade em curvas de raios menores, para que essas sejam usadas apenas em caso de necessidade. A superelevação de cada curva é calculada automaticamente. Inclua, exclua e edite linhas da tabela de curvas, conforme necessidade.",
        "help_text_14": "Crie um padrão. Você pode alterar o nome, copiar, apagar ou editar o seu padrão.",
        "help_text_1_tooltip": "A rampa no sentido principal começa no ponto inicial e vai em direção ao ponto final.",
        "help_text_2_tooltip": "A rampa máxima em sentido contrário é do ponto final ao inicial.",
        "help_text_3_tooltip": "Quanto maior o comprimento da curva vertical, menor a quantidade de ocorrências nas alternativas.",
        "help_text_4_tooltip": "Defina o coeficiente de velocidade para o cálculo da superelevação.",
        "help_text_5_tooltip": "Defina o raio mínimo e todas as curvas que queira usar na geometria horizontal da ferrovia.",
        "help_text_6_tooltip": "Coloque comprimentos de espiral ou zero, caso seja uma curva circular sem transição. Coloque penalidade em curvas de raios menores, para que essas sejam usadas apenas em caso de necessidade. A superelevação de cada curva é calculada automaticamente. Inclua, exclua e edite linhas da tabela de curvas, conforme necessidade.",
        "billing_data_obj_1": [
            { text: 'Data', value: 'date'},
            { text: 'Descrição', value: 'product.description'},
            { text: 'Status', value: 'status'},
            { text: 'Tipo - Título', value: 'calc_type'},
            { text: 'Valor', value: 'value'},
            { text: 'Saldo', value: 'balance_new'},
            { text: 'Ações', value: 'actions'},
        ],
        "billing_data_obj_2": [
            { text: 'Créditos', value: 'qtty'},
            { text: 'Preço ($)', value: 'price'},
            { text: 'Ações', value: 'actions'},
        ],
        "billing_text_data_1": "Pesquisar",
        "billing_text_data_2": "Linhas por página:",
        "detail_text_dialog_1":"Confirmar uso de",
        "detail_text_dialog_2":"Agora não",
        "detail_text_dialog_3":"Confirme",
        "help_text_home_1": "Arquivar cálculo",
        "help_text_home_2": "Contactar suporte",
        "status_text_1": "Iniciando cálculo dos corredores",
        "status_text_2": "Finalizando cálculo dos corredores",
        "status_text_3": "Iniciando cálculo das alternativas",
        "status_text_4": "Finalizando cálculo das alternativas",
        "Detail_table_header_text": [
            { text: 'Nome', value: 'name'},
            { text: 'Preço Estimado (', value: 'priceFormatted'},
            { text: 'Comprimento', value: 'lengthFormatted'},
            { text: 'Status', value: 'actions', align: 'center', sortable: false,},
            { text: 'Ações', value: 'optimization', align: 'center', sortable: false,},
        ],
        "Select_capex_dialog_texts_1":"Descrição",
        "Select_capex_dialog_texts_2":"Unidade",
        "Select_capex_dialog_texts_3":"Custo",
        "Select_capex_dialog_texts_4":"Cancelar",
        "Select_capex_dialog_texts_5":"Salvar",
        "error_msg_txt_2":"No momento não foi possivel realizar sua solicitação, tente novamente mais tarde.",
        "error_msg_txt_1":"Servidores encontram-se um pouco instaveis no momento, os cálculos podem levar um pouco mais de tempo para terminarem.",
        "error_msg_txt_3":"Servidores encontram-se um pouco instaveis no momento.",
        "text_home_1":"Etapa ",
        "text_home_2":"Atualizado ",
        "error_login_credentials_txt_1": "Usuário ou senha inválidos",
        "error_login_credentials_txt_2": "Sistema temporariamente fora do ar. Tente novamente mais tarde.",
        "map_alert_text_1":"Problema não identificado ao salvar pontos de passagem obrigatória.",
        "map_alert_text_2":"Por favor, desenhe ou anexe um poligono para determinar a área a ser calculada.",
        "map_alert_text_3":"Problema não identificado ao salvar área limite.",
        "map_alert_text_4":"Por favor selecione 2 pontos de passagem obrigatória.",
        "map_alert_text_5":"A distância minima entre os pontos deve ser de pelo menos 500m.",
        "opitimization_text_1":"Otimizar",
        "arealimit1kkm2_warning":" A área limite escolhida é maior que 1.000 km². Você será informado por e-mail quando essa etapa do cálculo estiver concluída. Tamanho da área ",
        "register_rules_txt_1":"Campo obrigatório",
        "register_rules_txt_2":"Porfavor digite sua senha.",
        "register_rules_txt_3":"Porfavor digite sua senha.",
        "register_rules_txt_4":"É necessario possuir ao menos um caractere especial",
        "register_rules_txt_5":"Mínimo 10 caracteres",
        "register_rules_txt_6":"O e-mail deve ser válido",
        "register_rules_txt_7":"CPF inválido",
        "text_dialog_calc_text_1":"Calcular relatórios detalhados",
        "text_dialog_calc_text_2":"Calcular otimização a partir da alternativa",
        "text_dialog_calc_text_3":"",
        "tooltip_detailed_btn_text_1":"Gerar relatórios detalhados",
        "tooltip_detailed_btn_text_2":"Otimizar alternativa",
        "tooltip_detailed_btn_text_3":"Link dos arquivos",
        "tooltip_home_btn_text_1":"Arquivos do cálculo",
        "area_is_way_too_big_warning_text":"A área é maior do que o limite permetido pelo WWCALC, porfavor volte uma etapa e redefina a area limite, para que seja menor que 5.000 km². ",
        "area_is_text":"A área é ",
        "capex_dialog_text_btn_1":"Trocar",
        "capex_dialog_text_btn_2":"Cancelar", 
        "alert_text_list_curves_txt_1":"O número de curvas enviado deve ser igual ao numero de curvas presente no alinhamento:  \nLista de curvas enviada: ",
        "alert_text_list_curves_txt_2":"\nNúmero de curvas: ",
        "countries_list": [
            'Afeganistão',
            'Albânia',
            'Argélia',
            'Andorra',
            'Angola',
            'Antígua e Barbuda',
            'Argentina',
            'Armênia',
            'Áustria',
            'Azerbaijão',
            'Bahrein',
            'Bangladesh',
            'Barbados',
            'Bielorrússia',
            'Bélgica',
            'Belize',
            'Benin',
            'Butão',
            'Bolívia',
            'Bósnia e Herzegovina',
            'Botsuana',
            'Brasil',
            'Brunei',
            'Bulgária',
            'Burquina Faso',
            'Burundi',
            'Cabo Verde',
            'Camboja',
            'Camarões',
            'Canadá',
            'República Centro-Africana',
            'Chade',
            'Ilhas do Canal',
            'Chile',
            'China',
            'Colômbia',
            'Comores',
            'Congo',
            'Costa Rica',
            'Costa do Marfim',
            'Croácia',
            'Cuba',
            'Chipre',
            'República Tcheca',
            'Dinamarca',
            'Djibuti',
            'Dominica',
            'República Dominicana',
            'República Democrática do Congo',
            'Equador',
            'Egito',
            'El Salvador',
            'Guiné Equatorial',
            'Eritreia',
            'Estônia',
            'Essuatíni',
            'Etiópia',
            'Ilhas Faroé',
            'Finlândia',
            'França',
            'Guiana Francesa',
            'Gabão',
            'Gâmbia',
            'Geórgia',
            'Alemanha',
            'Gana',
            'Gibraltar',
            'Grécia',
            'Granada',
            'Guatemala',
            'Guiné',
            'Guiné-Bissau',
            'Guiana',
            'Haiti',
            'Santa Sé',
            'Honduras',
            'Hong Kong',
            'Hungria',
            'Islândia',
            'Índia',
            'Indonésia',
            'Irã',
            'Iraque',
            'Irlanda',
            'Ilha de Man',
            'Israel',
            'Itália',
            'Jamaica',
            'Japão',
            'Jordânia',
            'Cazaquistão',
            'Quênia',
            'Kuwait',
            'Quirguistão',
            'Laos',
            'Letônia',
            'Líbano',
            'Lesoto',
            'Libéria',
            'Líbia',
            'Liechtenstein',
            'Lituânia',
            'Luxemburgo',
            'Macau',
            'Madagascar',
            'Malawi',
            'Malásia',
            'Maldivas',
            'Mali',
            'Malta',
            'Mauritânia',
            'Maurício',
            'Mayotte',
            'México',
            'Moldávia',
            'Mônaco',
            'Mongólia',
            'Montenegro',
            'Marrocos',
            'Moçambique',
            'Mianmar',
            'Namíbia',
            'Nepal',
            'Holanda',
            'Nicarágua',
            'Níger',
            'Nigéria',
            'Coreia do Norte',
            'Macedônia do Norte',
            'Noruega',
            'Omã',
            'Paquistão',
            'Panamá',
            'Paraguai',
            'Peru',
            'Filipinas',
            'Polônia',
            'Portugal',
            'Catar',
            'Reunião',
            'Romênia',
            'Rússia',
            'Ruanda',
            'Santa Helena',
            'São Cristóvão e Névis',
            'Santa Lúcia',
            'São Vicente e Granadinas',
            'San Marino',
            'São Tomé e Príncipe',
            'Arábia Saudita',
            'Senegal',
            'Sérvia',
            'Seychelles',
            'Serra Leoa',
            'Singapura',
            'Eslováquia',
            'Eslovênia',
            'Somália',
            'África do Sul',
            'Coreia do Sul',
            'Sudão do Sul',
            'Espanha',
            'Sri Lanka',
            'Estado da Palestina',
            'Sudão',
            'Suriname',
            'Suécia',
            'Suíça',
            'Síria',
            'Taiwan',
            'Tajiquistão',
            'Tanzânia',
            'Tailândia',
            'Bahamas',
            'Timor-Leste',
            'Togo',
            'Trinidad e Tobago',
            'Tunísia',
            'Turquia',
            'Turcomenistão',
            'Uganda',
            'Ucrânia',
            'Emirados Árabes Unidos',
            'Reino Unido',
            'Estados Unidos',
            'Uruguai',
            'Uzbequistão',
            'Venezuela',
            'Vietnã',
            'Sahara Ocidental',
            'Iêmen',
            'Zâmbia',
            'Zimbábue'
        ],
        "restrictions_menu": [
            "Adicionar",
            "Apagar",
            "Cancelar"
        ],
    }