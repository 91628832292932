import {hidrologyAxios} from '../helpers/interceptors'
// import store from '../store'

export default {
  state: {
    hidrologySubBase: undefined,
    hidrologyIDF: undefined,
  },
  mutations: {
    hidrologySubBase: (state, value) => state.hidrologySubBase = value,
    hidrologyIDF: (state, value) => state.hidrologyIDF = value,
  },
  getters: {
    hidrologySubBase: state => state.hidrologySubBase,
    hidrologyIDF: state => state.hidrologyIDF,
  },
  actions: {
    async hidrologySubBaseCall({commit}, data){
      try {
        const res = await hidrologyAxios.post('/hidrology/report/sub_basins_geojson', data)
        if(res.status==500){
          window.localStorage.setItem('service_status','unstable')
        }
        if(res.status==503){
          window.localStorage.setItem('service_status','offline')
        }
        if(res.status==200){
        // window.localStorage.setItem('service_status','stable')
          commit('hidrologySubBase',res.data)
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async hidrologyIDFCall({commit}, data){
      try {
        const res = await hidrologyAxios.post('/hidrology/report/idfs_geojson_in_sub_basins', data)
        if(res.status==500){
          window.localStorage.setItem('service_status','unstable')
        }
        if(res.status==503){
          window.localStorage.setItem('service_status','offline')
        }
        if(res.status==200){
        // window.localStorage.setItem('service_status','stable')
          commit('hidrologyIDF',res.data)
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
  }
}
