/* eslint-disable no-console */

import { register } from 'register-service-worker'
// console.log('process.env: ',process.env)
// if (process.env.NODE_ENV === 'production') {
  register(`${process.env.VUE_APP_BASE_URL}/service-worker.js`, {
    // register(`${process.env.BASE_URL}registerServiceWorker.js?v=${process.env.PACKAGE_VERSION}`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    // When the SW is updated we will dispatch an event we can listen to in our .vue file
    updated(registration) {
      console.log('New content is available; please refresh.')
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      )
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })

  // O codigo abaixo não funciona, foi manteido aqui para lembrar que ja foi testado e não funcionou não alterou absolutamente nada no resultado do que seria o tratamento de cache do navegador

//   const CACHE_NAME = 'my-app-cache-v1'; // Nome do cache, mude a versão conforme necessário
//   const urlsToCache = [
//     '/',          // A página principal
//     '/index.html', // Arquivos principais
//     '/styles.css',
//     '/app.js',
//     // Outros arquivos estáticos que você deseja armazenar em cache
//   ];

//   // Instala o service worker e faz o cache dos recursos
//   self.addEventListener('install', (event) => {
//     event.waitUntil(
//       caches.open(CACHE_NAME).then((cache) => {
//         return cache.addAll(urlsToCache);
//       })
//     );
//     // Força o service worker a pular o estado de espera e ativar imediatamente
//     self.skipWaiting();
//   });

//   // Ativa o service worker imediatamente e assume o controle das páginas abertas
//   self.addEventListener('activate', (event) => {
//     event.waitUntil(
//       caches.keys().then((cacheNames) => {
//         return Promise.all(
//           cacheNames.map((cache) => {
//             if (cache !== CACHE_NAME) {
//               return caches.delete(cache); // Deleta caches antigos
//             }
//           })
//         );
//       })
//     );
//     return self.clients.claim(); // Assume controle imediatamente
//   });

//   // Intercepta solicitações e tenta responder com cache, ou faz fetch se não estiver em cache
//   self.addEventListener('fetch', (event) => {
//     event.respondWith(
//       caches.match(event.request).then((response) => {
//         // Retorna o cache se encontrado, ou faz um fetch
//         return response || fetch(event.request);
//       })
//     );
//   });
// // }
