import axios from 'axios';
import { URLS } from '../constants';
import store from '../store';
import router from '../router/index.js';

axios.defaults.headers.post["Content-Type"] = "application/json";

const apiAxios = axios.create({ baseURL: URLS.api });
const hidrologyAxios = axios.create({ baseURL: URLS.route });
const authAxios = axios.create({ baseURL: URLS.auth });

export default function interceptorsSetup() {
  const setAuthHeader = (config) => {
    const accessToken = store.getters.accessToken;
    if (accessToken) {
      config.headers.Authorization = `bearer ${accessToken}`;
    }
    return config;
  };

  const handleRequestError = (err) => Promise.reject(err);

  apiAxios.interceptors.request.use(setAuthHeader, handleRequestError);
  hidrologyAxios.interceptors.request.use(setAuthHeader, handleRequestError);
  authAxios.interceptors.request.use(setAuthHeader, handleRequestError);

  authAxios.interceptors.response.use(
    (response) => response,
    async (err) => {
      console.log(err.response.data.message);
      return err.response;
    }
  );

  apiAxios.interceptors.response.use(
    (response) => response,
    async (err) => {
      if (err.response && err.response.status === 401) {
        console.log('apiAxios error 401');
        await store.dispatch('logout');
        router.push('/login');
        return false;
      }
      // console.log(err.response.data);
      return err.response;
    }
  );
}

export { apiAxios, hidrologyAxios, authAxios };