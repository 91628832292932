import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store/index'
import interceptorsSetup from './helpers/interceptors'
import '../service-worker.js'
import VueLayers from 'vuelayers'
import router from './router/index.js'
import { VueMaskDirective } from 'v-mask'
import VueCryptojs from 'vue-cryptojs'

// console.log('teste 1')

// if ('serviceWorker' in navigator) {
//   console.log('teste 2')
//   navigator.serviceWorker.register('/service-worker.js').then(registration => {
//     registration.update()
//   })
// }

// console.log('teste 3')
// const version = '0.1.125'
// fetch('/package.json')
// .then((res)=>res.json)
// .then((data)=>{
//   if (data.version != version){
//     caches.keys().then(function (names){
//       for (let name of names) caches.delete(name)
//     })
//     location.reload()
//   }
// });

Vue.use(VueCryptojs)

Vue.directive('mask', VueMaskDirective);

Vue.use(VueLayers)

interceptorsSetup()

new Vue({
  router,
  vuetify,
  store,
  // i18n,
  render: h => h(App)
}).$mount('#app')
