<template>
  <v-app>
    <v-main id="div1">
      <v-alert v-if="errorMessage && errorMessage == 'Servidores encontram-se um pouco instaveis no momento, os cálculos podem levar um pouco mais de tempo para terminarem.' ||
      errorMessage == 'Services unstable at the moment calcs may take a little longer than usual to finish.' " dense border="top" color="warning" dark>
        {{ errorMessage }}
      </v-alert>
      <v-alert v-else-if="errorMessage != undefined && errorMessage && errorMessage != 'Servidores encontram-se um pouco instaveis no momento, os cálculos podem levar um pouco mais de tempo para terminarem.' &&
      errorMessage != 'Services unstable at the moment calcs may take a little longer than usual to finish.' " dense border="top" color="error" dark>
        {{ errorMessage }}
      </v-alert>
      <router-view :key="$route.fullPath"/>
    </v-main>
    <v-footer app width="100%" :color="color" dark class="d-flex align-center justify-center">
      <small>© 2024 WWCALC - {{ this.selectedLanguage['rigths_1'] }}. V{{ completeAppVersion }}</small>
      <small v-if="!$vuetify.breakpoint.xs"><v-btn x-small text @click="$router.push({name: selectedLanguage['terms_redirect']})">{{ this.selectedLanguage['termsOfService_tittle'] }}</v-btn></small>
      <small v-if="!$vuetify.breakpoint.xs"><v-btn x-small text @click="$router.push({name: selectedLanguage['poli_redirect']})">{{ this.selectedLanguage['privacyPolicy_tittle'] }}</v-btn></small>
    </v-footer>
  </v-app>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { portuguese } from './languages/pt-br'
  import { english } from './languages/en'
        
  export default {
    name: 'App',
    computed: {
      ...mapGetters(['isAuthenticated','appVersion','passwordResetRequested', 'profile']),
      completeAppVersion() {
        if(process.env.VUE_APP_VAR_ENV=='prd'){
          return this.appVersion
        }
        return this.appVersion+' '+process.env.VUE_APP_VAR_ENV
      },
      selectedLanguage() {
        if(this.profile != undefined){
          if (this.profile['language'] === 'en') {
            return english;
          } else {
            return portuguese;
          }
        } else if(localStorage.getItem('loginLanguage')){
          if(localStorage.getItem('loginLanguage') === 'en'){
            return english;
          } else {
            return portuguese;
          }
        } else {
          return portuguese;
        }
        },
    },
    mounted: async function(){
      window.localStorage.setItem('service_status','stable')
      if(!this.isAuthenticated){
        if(!this.passwordResetRequested){
          this.$router.push({ name: 'login' })
        }
        // é por isso que volta pra home quando recarrega a pagina
        // this.$router.push({ name: 'home' })
      }
      setInterval(() => {
        var serviceS = window.localStorage.getItem('service_status')
        var msgError = undefined
        if(serviceS == 'unstable'){
          msgError = this.selectedLanguage['error_msg_txt_2']
        }
        else if(serviceS == 'offline'){
          msgError = this.selectedLanguage['error_msg_txt_1']
        } else if(serviceS == 'stable'){
          msgError = undefined
        }
        this.errorMessage = msgError
        // console.log('msg is ', msgError)
      }, 5000)
    },
    data: () => ({
      color: '#0A7373',
      errorMessage: undefined,
    }),
    methods: {
      ...mapActions(['setAppVersion'])
    }
  }
</script>

<style scoped>
  .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    color: #FFFFFF;
  }
</style>
<style>
.no-background-hover::before {
  background-color: transparent !important;
}
</style>