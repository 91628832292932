import axios from 'axios'

const apiKey = 'Y9fl1xtvAEv51Gh5ehFjQlICtJAaOrmknr97gpHe'

var exchanges = {
  'AUD':  1.48,
  'BGN':  1.78,
  'BRL':  5.04,
  'CAD':  1.34,
  'CHF':  0.89,
  'CNY':  6.87,
  'CZK':  21.43,
  'DKK':  6.79,
  'EUR':  0.91,
  'GBP':  0.80,
  'HKD':  7.84,
  'HRK':  6.86,
  'HUF':  343.25,
  'IDR':  14954.02, 
  'ILS':  3.65,
  'INR':  82.14, 
  'ISK':  136.31, 
  'JPY':  134.00, 
  'KRW':  1323.95,
  'MXN':  17.99,
  'MYR':  4.43,
  'NOK':  10.58,
  'NZD':  1.61,
  'PHP':  55.98,
  'PLN':  4.19,
  'RON':  4.49,
  'RUB':  81.80,
  'SEK':  10.30,
  'SGD':  1.33,
  'THB':  34.31,
  'TRY':  19.40,
  'USD':  1,
  'ZAR': 18.03
}

export default {
  state: {
    exchanges: exchanges,
  },
  mutations: {
    exchanges: (state, value) => state.exchanges = value,
  },
  getters: {
    exchanges: state => state.exchanges,
  },
  actions: {
    async getExchange({commit}) {
      try {
        const res = await axios('https://api.freecurrencyapi.com/v1/latest?apikey='+apiKey)
        if(res.status==500){
          window.localStorage.setItem('service_status','unstable')
        }
        if(res.status==503){
          window.localStorage.setItem('service_status','offline')
        }
        if(res.status==200){
        // window.localStorage.setItem('service_status','stable')
          var exch = res.data.data
          Object.keys(exch).forEach(key => {
            exchanges[key] = exch[key].toFixed(2)
          })
          commit('exchanges',exchanges)
          return true
        }
        // console.log('Used static exchange values')
        return exchanges
      } catch (error) {
        // console.log('Error Used static exchange values')
        return exchanges
      }    
    },
  }
}
