import {apiAxios} from '../helpers/interceptors'
import store from '../store/index'

export default {
  state: {
    // reports: undefined,
  },
  mutations: {
    reset: (state) => {
      Object.keys(state).forEach(key => {
        // console.log('reset ',key,': ',state[key])
        state[key]=undefined
      })
    }
  },
  getters: {
    // reports: state => state.reports,
  },
  actions: {
    async corridorCalc(context,calcId) {
      const res = await apiAxios.post('corridor_calc/'+calcId,{'password': store.getters.cloudPassword})
      // console.log('res ', res)
      if(res.status==500){
        // console.log('entrou aqui 500')
        window.localStorage.setItem('service_status','unstable')
      }
      if(res.status==503){
        // console.log('entrou aqui 503')
        window.localStorage.setItem('service_status','offline')
      }
      if(res.status==200){
        // console.log('entrou aqui 200')
        // window.localStorage.setItem('service_status','stable')
        return true
      }
      return res
    },
    async basicCalc(context,calcId) {
      const res = await apiAxios.post('basic_calc/'+calcId,{'password': store.getters.cloudPassword})
      if(res.status==500){
        window.localStorage.setItem('service_status','unstable')
      }
      if(res.status==503){
        window.localStorage.setItem('service_status','offline')
      }
      if(res.status==200){
      // window.localStorage.setItem('service_status','stable')
        return true
      }
      return res
    },
    async detailedCalc(context,{calcId,alternativeNumber}){
        const res = await apiAxios.post('detailed_calc/'+calcId+'/'+alternativeNumber,{'password': store.getters.cloudPassword})
        if(res.status==500){
          window.localStorage.setItem('service_status','unstable')
        }
        if(res.status==503){
          window.localStorage.setItem('service_status','offline')
        }
        if(res.status==200){
        // window.localStorage.setItem('service_status','stable')
          return true
        }
        return res
    },
  }
}
